import '../styles/components/Transitioner.css';

interface TransitionerProps {
  child: React.ReactNode;
  transitioned: boolean;
}
const Transitioner = ({ child, transitioned }: TransitionerProps) => {
  return (
    <section className={transitioned ? 'slide-in' : 'slide-out'}>
      {child}
    </section>
  );
}

export default Transitioner;