
interface ModalWhyProps {
  handleShow: () => void;
}

const ModalWhy = ({handleShow}: ModalWhyProps) => {
    return (
        <section className="w3-modal w3-animate-opacity" style={{display: "block", zIndex: "10001"}}>
          <aside className="w3-modal-content">
            <span onClick={handleShow} className="w3-button w3-display-topright">&times;</span>
            <aside className="w3-container">
              <blockquote>
                I've found that there are many people with the hunger to become #founders, but they struggle finding a technical founder. That or they flounder for a while because they don't quite understand what is necessary for their POC or MVP.
              </blockquote>
              <blockquote>
                What's worse is that, over the years, I've seen tech shops hold some folks ransom for a stake in their project.
              </blockquote>
              <blockquote>
                This is why, I have chosen to set my time aside for this. To make sure that founders in need of technical guidance are protected.
              </blockquote>
            </aside>
          </aside>
        </section>
    )
}

export default ModalWhy;