import { useRouteError, ErrorResponse } from 'react-router-dom';

const ErrorPage = () => {
  const error = useRouteError() as ErrorResponse;
  return (
    <section id="error-page">
      <header className="App-header">
        <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText || error.data?.message}</i>
      </p>
      <p>
        <a href="/">Back to home</a>
      </p>
      </header>
    </section>
  );
}
  
export default ErrorPage;