// import Avatar from "react-avatar";
import ModalWhy from '../components/ModalWhy';
import Service from '../components/Services';
import Transitioner from '../components/Transitioner';
import headshot from '../resources/images/BenHShot_2021_SM.jpg';
import logo from '../resources/images/Flex_CTO_cropped.png';
import '../styles/pages/Home.css';
import { Fragment, useState } from 'react';

const Home = () => {
  const kofiBtnImageSrc = 'https://storage.ko-fi.com/cdn/brandasset/kofi_button_red.png?_gl=1*blhbqz*_ga*ODIzOTQ0MDMwLjE2OTg4MTgzOTc.*_ga_M13FZ7VQ2C*MTcwMDU3ODMyNy4xMi4xLjE3MDA1NzkxMTguNTkuMC4w';
  // const kofiSmall = 'https://storage.ko-fi.com/cdn/brandasset/logo_white_stroke.png?_gl=1*1ds18v3*_ga*ODIzOTQ0MDMwLjE2OTg4MTgzOTc.*_ga_M13FZ7VQ2C*MTcwMDU5NDg2OC4xMy4xLjE3MDA1OTU2NjYuNDMuMC4w';
  const activeBtn = {backgroundColor: "#ff7733"};

  const [show, setShow] = useState(false);
  const handleShow = () => {
    console.log('show: ', show);
    setShow(ss => !ss);
  }
  const [transitionNum, setTransitionNum] = useState<number>(0);
  const transitionHandler = (num: number) => {
    setTransitionNum(num);
  }

  return (
    <section>
      <header className="App-header">
        <img title="Flex_CTO" className="home-logo-top" src={logo} alt="Flex_CTO Logo" />
        {show &&
          <ModalWhy handleShow={handleShow} />
        }
        <table>
          <tbody>
            <thead>
              <tr>
                <th className="small-screen-button-container">
                  <aside className="home-button-container w3-animate-top">
                    <button onClick={() => transitionHandler(0)} className="home-button" style={transitionNum === 0 ? activeBtn : {}} title="Home" aria-label="Home"><i className="fas fa-home"></i></button>
                    &nbsp;<button style={transitionNum === 1 ? activeBtn : {}} onClick={() => transitionHandler(1)} className="home-button" type="button">About</button>
                    <button style={transitionNum === 2 ? activeBtn : {}} onClick={() => transitionHandler(2)} className="home-button" type="button">Services</button>
                  </aside>
                </th>
                <th className="small-screen-button-container">
                  &nbsp;<a href="https://calendly.com/benjamin-juarez/60min" className="home-button" title="Calendly" aria-label="Calendly" target='_blank' rel="noreferrer"><i style={{color: "lightgreen"}} className="fas fa-calendar-plus" ></i></a>
                  <a href='https://ko-fi.com/C0C2QPCEG' target='_blank' rel="noreferrer">
                  <img className="kofi-btn" src={kofiBtnImageSrc} alt='Support me at ko-fi.com' />
                  {/* <picture>
                    <source srcSet={kofiBtnImageSrc} media="(min-width: 768px)" />
                    <img className="kofi-btn" src={kofiSmall} alt='Support me at ko-fi.com' />
                  </picture> */}
                  </a>
                </th>
              </tr>
            </thead>
            <tr>
              <td>
                <aside className="home-description w3-animate-left">
                  <Transitioner
                    transitioned={transitionNum === 0}
                    child={ 
                      <Fragment>
                        <p>Fractional CTO services for startups and early-stage ideas needing technical guidance.</p>
                        <aside className="home-hook">
                          <p><span role="img" aria-label="rocket">🚀</span> Equitable pay-what-you-wish first hour <span role="img" aria-label="rocket">🚀</span></p>
                        </aside>
                        <br />
                        <button className="w3-button w3-medium w3-border" onClick={handleShow}>Why is this important?</button>
                      </Fragment>
                    }
                  />
                  <Transitioner
                    transitioned={transitionNum === 1}
                    child={
                      <Fragment>
                        <p>Ben Juárez is an engineer, co-founder, and CTO with a passion for guiding new startups on their technical journey.</p>
                        <aside className="home-hook">
                          <a style={{textDecoration: "none"}} target="_blank" rel="noreferrer" title="LinkedIn" aria-label="LinkedIn" href="https://www.linkedin.com/in/benjamin-juarez-4826862a/">
                            LinkedIn deets: &nbsp;<i className="fab fa-linkedin"></i>
                          </a>
                        </aside>
                      </Fragment>
                    }
                  />
                  <Transitioner
                    transitioned={transitionNum === 2}
                    child={
                      <Fragment>
                        <Service />
                      </Fragment>
                    }
                  />
                </aside>
              </td>
              <td>
                <aside className={`home-images-container ${transitionNum === 1 ? 'home-main-img-animate' : ''}`}>
                  <img title="Ben Juárez" className="home-main-img-below w3-animate-opacity" src={headshot} alt="Ben Juárez headshot" />
                </aside>
              </td>
            </tr>
          </tbody>
        </table>   
      </header>
    </section>
  );
}
 
export default Home;