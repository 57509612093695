import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './styles/fonts.css';
import './App.css';
import Home from './pages/Home';
import ErrorPage from './pages/ErrorPage';

function App() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <Home />,
      errorElement: <ErrorPage />
    },
  ]);

  return (
    <main role='main' className="App">
      <RouterProvider router={router} />
    </main>
  );
}

export default App;
