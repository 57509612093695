import { useState } from 'react';
import '../styles/components/Services.css';

const Service = () => {
  const [expanded, setExpanded] = useState<null | number>(null);
  const [expanded2, setExpanded2] = useState<null | number>(null);

  const handleExpand = (index: number) => {
    if (expanded === index) {
      setExpanded(null);
      setExpanded2(null);
    } else {
      setExpanded(index);
      setExpanded2(null);
    }
  }

  const handleExpand2 = (index: number) => {
    if (expanded2 === index) {
      setExpanded2(null);
    } else {
      setExpanded2(index);
    }
  }

  const data = [
    {
      title: 'Cloud-based Tech Solutions',
      summary: 'Create strategic technology roadmaps, develop robust technology architectures, and build powerful tech stacks.',
      points: [
        'Create strategic technology roadmaps outlining future plans and priorities',
        'Develop robust technology architectures that structure systems and tools for scale and efficiency',
        'Design powerful tech stacks using the right components and technologies for specific needs',
      ],
    },
    {
      title: 'AI Solutions',
      summary: 'Specialized consultation services to help businesses (SME) integrate AI into their daily operations.',
      points: [
        'Identify key areas for AI integration ensuring a seamless transition',
        'Offer tailored AI solutions enhancing operational efficiency reducing costs driving innovation',
        'Leverage cutting-edge AI technologies to stay ahead of the competition unlock new growth opportunities',
      ],
    },
    {
      title: 'Team Build Solutions',
      summary: 'Tech team building solutions for startups looking to hire talent.',
      points: [
        'Develop tech team building strategies tailored for startups',
        'Identify and attract top talent to meet your startup\'s unique needs',
        'Streamline the hiring process to ensure efficient onboarding'
      ],
    },
  ];  

  return (
    <section style={{marginTop: "2.5rem"}}>
      {data.map((item, index) => (
        <aside key={index}>
          <button className={`service-button ${expanded === index ? "activated" : ""}`} onClick={() => handleExpand(index)}>
            {item.title} <i className={`${expanded === index ? "fas fa-angle-up" : "fas fa-angle-down"}`}></i>
          </button>
          {expanded === index && (
            <aside className="w3-animate-opacity">
              <p style={{textAlign: "start"}}>{item.summary}</p>
              <button className="button-like-anchor" onClick={() => handleExpand2(index)}>Dig Deeper</button>
            </aside>
            
          )}
          {expanded2 === index && (
            <ul className="w3-animate-opacity fa-ul center-ul">
              {item.points.map((point, i) => (
                <li key={i}><span className="fa-li"><i style={{color: "lightgreen"}} className="fas fa-check-square"></i></span>{point}</li>
              ))}
            </ul>
          )}
        </aside>
      ))}
    </section>
  );
}

export default Service;